<template>
  <manger-page-header
    v-model:value="btnIndex"
    :btnList="btnList"
    :search="true"
  >
  </manger-page-header>
  <div class="content box-shadow">
    <h4 class="head-title">{{ school_name }}</h4>
    <p class="head-title-con">
      保存或复制下方二维码，或者复制授权，发送给其他用户。用户可手动输入，或通过App内部扫描二维码功能加入。
    </p>
    <div class="code" v-if="secret_key">
      <vue-qr :text="secret_key" :size="200"></vue-qr>
    </div>
    <p v-else class="code-none">暂无二维码</p>
    <p class="tips" v-if="secret_key">二维码有效期至：永久</p>
    <div class="flex flex-align-c code-con-box">
      <span class="code-con">{{ secret_key }}</span>
      <a-button
        class="tag-read"
        type="primary"
        data-clipboard-action="copy"
        :data-clipboard-text="secret_key"
        @click="handleCopy"
        >复制授权</a-button
      >
    </div>
    <div class="flex flex-align-c code-con-box mt-20">
      有效期：
      <a-radio-group name="radioGroup" v-model:value="expires_type">
        <a-radio :value="0">永久</a-radio>
        <a-radio :value="1">自定义</a-radio>
      </a-radio-group>
      <a-date-picker
        class="mr-5"
        v-model:value="expires_time"
        placeholder="选择截止日期"
        inputReadOnly
        :disabled="expires_type === 0"
      />
      <a-button type="primary" @click="fetchGenerateCode">重新生成</a-button>
    </div>
    <p class="col-red mt-20">注意：重新生成后，原二维码即失效</p>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import mangerPageHeader from '@/components/btn-and-search.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import { getAuthCode, generateCode } from '@/api/index'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { message } from 'ant-design-vue'
import Clipboard from 'clipboard'
import { getInfo } from '@/api/cookie'
const btnList = [
  {
    id: 1,
    btnName: '激活管理'
  }
]
export default defineComponent({
  name: 'ActiveManager',
  components: {
    mangerPageHeader,
    vueQr
  },
  setup() {
    const user = getInfo()
    const school_name = JSON.parse(user).school_name
    const { btnIndex } = btnAndSearchHooks()
    const secret_key = ref('')
    const expires_time = ref('')
    const init_expires_time = ref('')
    const expires_type = ref(0)
    const fetchAuthCode = () => {
      getAuthCode().then(res => {
        secret_key.value = res.data.secret_key
        init_expires_time.value = res.data.expires_time
      })
    }
    // 生成授权码
    const fetchGenerateCode = () => {
      if (expires_type.value === 1 && expires_time.value === '') {
        message.error('请选择有效日期')
        return false
      }
      generateCode({
        expires_time: expires_type.value === 0 ? '2099-1-1' : expires_time.value
      }).then(res => {
        secret_key.value = res.data
        message.success('生成授权码成功')
        fetchAuthCode()
      })
    }
    // 复制
    const handleCopy = () => {
      const clipboard = new Clipboard('.tag-read')
      clipboard.on('success', () => {
        clipboard.destroy()
        message.success('复制成功')
      })
      clipboard.on('error', () => {
        // 不支持复制
        clipboard.destroy()
        message.success('您的浏览器不支持复制，请手动复制')
      })
    }
    onMounted(() => {
      fetchAuthCode()
    })
    return {
      school_name,
      btnList,
      btnIndex,
      secret_key,
      expires_type,
      expires_time,
      fetchGenerateCode,
      handleCopy
    }
  }
})
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  height: calc(100vh - 220px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .head-title {
    font-size: 16px;
    font-weight: bolder;
  }
  .head-title-con {
    padding: 20px 0;
  }
}
.code {
  border: 1px solid #eee;
}
.tips {
  padding: 10px 0;
  color: #999;
}
.code-con-box {
  padding: 10px;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 4px;
  .code-con {
    padding: 10px;
    width: 400px;
    word-break: break-word;
    color: #999;
    // border: 1px solid #eee;
    // background: #F5F7FA;
  }
}
.code-none {
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  border: 1px solid #eee;
}
</style>
